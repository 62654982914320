import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import styled, { css, keyframes } from "styled-components";
import axios from "axios";
import ImageSelector from "../components/ImageSelector";
import Textinput from "../components/Textinput";
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import userSlice from "../slice/user";
import ogs from "open-graph-scraper"; // Import the open-graph-scraper library

export default function Post_Write() {
  const location = useLocation();
  const existTitle = location.state?.title;
  const existContent = location.state?.content;
  const existMainimg = location.state?.mainimg && "https://media.dplanit.co.kr/"+location.state?.mainimg;
  const existThumbimg = location.state?.thumbimg && "https://media.dplanit.co.kr/"+location.state?.thumbimg;
  const existType = location.state?.type;
  const postno = location.state?.postno;
  const isEdit = location.state?.isEdit;
  const buttonitem = ["고위험 임신", "FAQ"];
  const [catergoryIndex, setCategoryIndex] = useState(existType || 0);
  const [title, setTitle] = useState(existTitle ||"");
  const [content, setContent] = useState(existContent || "");
  
  const [image, setImage] = useState(existMainimg || null);
  const [imageData, setImageData] = useState(existMainimg || null);
  
  const [thumbimg, setThumbimg] = useState(existThumbimg || null);
  const [thumbData, setThumbData] = useState(existThumbimg || null);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  //const editorRef = React.createRef();
  const editorRef = useRef(null);
  const navigation = useNavigate();
  const accesstoken = useSelector((state) => state.user.accesstoken);
  useEffect(()=>{},[accesstoken,loading])
  
  const handlePaste = async (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");

    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

    if (urlRegex.test(pastedData)) {
      event.preventDefault();

      try {
        setLoading(true);
        const ogData = await makeNewLink(pastedData);
        const ogHTML = ogData.data.data.data;
        
        const updatedContent = content.replace(new RegExp(pastedData, 'g'), '') + ogHTML;
        editorRef.current.getInstance().setMarkdown(updatedContent);
        
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch OG data:", error);
      }
    }
  };
  const makeNewLink =async(url)=>{
    const response = await axios.post(
      `${
        process.env.NODE_ENV == "development"
          ? "http://localhost:2500"
          : "https://dplanitp.dplanit.co.kr"
      }/admin/post/newlink`,
      {url : url},
      {
        headers: {
          accesstoken: localStorage.getItem("accessToken"),
        },
        withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
      }
    );
    return response;
  }
  const handleChange = () => {
    setContent(editorRef.current?.getInstance().getHTML());
  };
  const InsertPost = async (title, content, type, thumbimg, mainimg) => {
    if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
    }
    if (!content) {
      swal("내용을 입력해주세요", { icon: "error" });
    }
    if (!thumbimg) {
      swal("썸네일 이미지를 입력해주세요", { icon: "error" });
    }
    if (!mainimg) {
      swal("메인 이미지를 입력해주세요", { icon: "error" });
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("type", type);
    formData.append("thumbimg", thumbimg);
    formData.append("mainimg", mainimg);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/post/insert`,
        formData,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem('accessToken',newAct)
      dispatch(
        userSlice.actions.updateAccessToken({
          accesstoken: localStorage.getItem('accessToken'),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/post/insert`,
        formData,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };

  const UpdatePost = async (title, content, type, thumbimg, mainimg) => {
    if (!title) {
      swal("제목을 입력해주세요", { icon: "error" });
    }
    if (!content) {
      swal("내용을 입력해주세요", { icon: "error" });
    }
    if (!thumbimg) {
      swal("썸네일 이미지를 입력해주세요", { icon: "error" });
    }
    if (!mainimg) {
      swal("메인 이미지를 입력해주세요", { icon: "error" });
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("type", type);
    formData.append("thumbimg", thumbimg);
    formData.append("mainimg", mainimg);
    formData.append("postno", postno);
    try {
      await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/post/update`,
        formData,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem('accessToken',newAct)
      dispatch(
        userSlice.actions.updateAccessToken({
          accesstoken: localStorage.getItem('accessToken'),
        })
      );
      const response_2 = await axios.post(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/post/update`,
        formData,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      if (response_2.data.popdata.poptext) {
        swal(response_2.data.popdata.poptext, {
          icon: "error",
        });
      }
      swal("등록되었습니다!", {
        icon: "success",
      });
      navigation("/post");
    }
  };
  return (
    <Layout
      children={
        <>
          <VarticalContainer>
            <HeaderCon>
              <HorizontalContainer>
                <MainTitle>포스트 등록</MainTitle>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <text style={{ color: "#888" }}>홈 &nbsp;&nbsp;</text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ width: "19px", height: "19px" }}
                  />
                  <text style={{ color: "#888" }}>
                    &nbsp;&nbsp;포스트관리&nbsp;&nbsp;
                  </text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ Width: "19px", height: "19px" }}
                  />
                  <text>&nbsp;&nbsp;포스트 등록</text>
                </div>
              </HorizontalContainer>
              <div>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-between",
                  paddingTop: "10px",
                  paddingBottom: "20px",
                  paddingLeft: "55px",
                  paddingRight: "55px",
                }}
              >
                <div>
                  {buttonitem.map((item, index) => (
                    <Button
                      onClick={() => {
                        setCategoryIndex(index);
                      }}
                      style={{
                        borderColor:
                          catergoryIndex === index ? "black" : "#C4C4C4",
                        cursor: "pointer",
                      }}
                    >
                      {item}
                    </Button>
                  ))}
                </div>
                <div>
                  <ColorButton
                    style={{
                      background: "#fff",
                      color: "#FEC009",
                      borderColor: "#FEC009",
                    }}
                    onClick={() => {
                        if(isEdit){
                          UpdatePost(
                            title,
                            content,
                            catergoryIndex,
                            thumbData,
                            imageData
                          );
                        } else {
                          InsertPost(
                            title,
                            content,
                            catergoryIndex,
                            thumbData,
                            imageData
                          );
                        }
                    }}
                  >
                    <text>등록하기</text>
                  </ColorButton>
                </div>
              </div>
            </HeaderCon>

            <ThumbCon>
              <text style={{ fontWeight: 600, fontsize: 16 }}>썸네일</text>
              <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "40px" }}>
                  <text>리스트 썸네일</text>
                </div>
                <div>
                  <ImageSelector
                    image={thumbimg}
                    setImage={setThumbimg}
                    setData={setThumbData}
                    width="288px"
                    height="145px"
                  />
                  <div style={{ marginTop: "20px" }}>
                    <text>(첨부 크기 : 314 x 158)</text>
                  </div>
                </div>
              </div>
            </ThumbCon>

            <DetailCon>
              <text style={{ fontWeight: 600, fontsize: 16 }}>상세 이미지</text>
              <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "40px" }}>
                  <text>메인 이미지</text>
                </div>
                <div>
                  <ImageSelector
                    image={image}
                    setImage={setImage}
                    setData={setImageData}
                    width="133px"
                    height="145px"
                  />
                  <div style={{ marginTop: "20px" }}>
                    <text>(첨부 크기 : 360 x 389)</text>
                  </div>
                </div>
              </div>
            </DetailCon>

            <TitleCon>
              <text style={{ fontWeight: 600, fontsize: 16 }}>제목</text>
              <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div>
              <Textinput text={title} setText={setTitle} />
            </TitleCon>

            <WriteCon onPaste={handlePaste}>
              <text style={{ fontWeight: 600, fontsize: 16 }}>내용</text>
              <div style={{ marginBottom: "40px", marginTop: "30px" }}>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div>
              <Editor
                initialValue={content}
                ref={editorRef}
                onChange={handleChange}
                placeholder="내용을 입력해주세요."
                previewStyle="vertical" // 미리보기 스타일 지정
                height="300px" // 에디터 창 높이
                initialEditType="wysiwyg" // 초기 입력모드 설정(디폴트 markdown)
                toolbarItems={[
                  // 툴바 옵션 설정
                  ["heading", "bold", "italic", "strike"],
                  ["hr", "quote"],
                  ["ul", "ol", "task", "indent", "outdent"],
                  ["table", "image", "link"],
                  ["code", "codeblock"],
                ]}
              ></Editor>
            </WriteCon>
          </VarticalContainer>
        </>
      }
    ></Layout>
  );
}
const VarticalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 90vh;
`;
const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 30px 60px;
`;
const HeaderCon = styled.div`
  flex: 0.1;
  background-color: #fff;
  box-sizing: border-box;
`;
const ThumbCon = styled.div`
  flex: 0.2;
  background-color: #fff;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const DetailCon = styled.div`
  flex: 0.2;
  background-color: #fff;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const TitleCon = styled.div`
  flex: 0.14;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;
const WriteCon = styled.div`
  flex: 0.36;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px 60px 30px 60px;
`;

const ColorButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #fec009;
  border-radius: 20px;
  cursor: pointer;
  height: 34px;
  width: 101px;
  border-radius: 20px;
  margin-top: 10px;
`;
const Button = styled.button`
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 4px;
  width: 174px;
  height: 54px;
  margin-right: 6.76px;
`;

const MainTitle = styled.text`
  font-family: "NanumBarunGothicOTF";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  display: flex;
  align-items: center;
`;
