import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import swal from "sweetalert";
export default function Home_Chat() {
  const [catergoryIndex, setCategoryIndex] = useState(0);
  const categories = ["전체", "대기", "진행중"];
  const navigate = useNavigate();
  return (
    <div style={{ flex: 1, position: "relative" }}>
      <div className={`Home_title`}>코칭 미답변 채팅</div>
        <img
          src={process.env.PUBLIC_URL + "/ico_reset.png"}
          className={`refresh_button`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            swal("준비중입니다.", { icon: "warning" });
          }}
        />
        <div className={`Home_line subline`}/>
      <HorizontalContainer style={{marginBottom:0}}>
        {categories.map((item, index) => (
          <ChatButtonContainer
            key={index}
            onClick={() => setCategoryIndex(index)}
          >
            {catergoryIndex == 0
              ? index === 0 && (
                  <>
                    <img
                      style={{ cursor: "pointer", marginLeft:-3 }}
                      src={process.env.PUBLIC_URL + "/ico_chat_all_on.png"}
                    ></img>
                    <ChatButtonText style={{marginLeft:-4}}>{item} 0</ChatButtonText>
                    <div className={`btnButtonLine`}></div>
                  </>
                )
              : index === 0 && (
                  <>
                    <img
                      style={{ cursor: "pointer", marginLeft:-3 }}
                      src={process.env.PUBLIC_URL + "/ico_chat_all_off.png"}
                    ></img>
                    <ChatButtonoff style={{marginLeft:-4}}>{item} 0</ChatButtonoff>
                    <div className={`btnButtonLineOff`}></div>
                  </>
                )}
            {catergoryIndex == 1
              ? index === 1 && (
                  <>
                    <img
                      style={{ cursor: "pointer", marginLeft:1 }}
                      src={process.env.PUBLIC_URL + "/ico_chat_standby_on.png"}
                    ></img>
                    <ChatButtonText>{item}</ChatButtonText>
                    <div className={`btnButtonLine`}></div>
                  </>
                )
              : index === 1 && (
                  <>
                    <img
                      style={{ cursor: "pointer", marginLeft:1 }}
                      src={process.env.PUBLIC_URL + "/ico_chat_standby_off.png"}
                    ></img>
                    <ChatButtonoff>{item}</ChatButtonoff>
                    <div className={`btnButtonLineOff`}></div>
                  </>
                )}
            {catergoryIndex == 2
              ? index === 2 && (
                  <>
                    <img
                      style={{ cursor: "pointer", marginLeft:-16 }}
                      src={process.env.PUBLIC_URL + "/ico_chat_ing_on.png"}
                    ></img>
                    <ChatButtonText style={{marginLeft:-16 }}>{item}</ChatButtonText>
                    <div className={`btnButtonLine`}></div>
                  </>
                )
              : index === 2 && (
                  <>
                    <img
                      style={{ cursor: "pointer", marginLeft:-16 }}
                      src={process.env.PUBLIC_URL + "/ico_chat_ing_off.png"}
                    ></img>
                    <ChatButtonoff style={{marginLeft:-16 }}>{item}</ChatButtonoff>
                    <div className={`btnButtonLineOff`}></div>
                  </>
                )}
          </ChatButtonContainer>
        ))}
      </HorizontalContainer>  
      <div className={`Home_line buttonline`}/>
      <div style={{height:220, overflow:"hidden"}}>
      <HorizontalContainer>
        <QuestionText>Q.&nbsp;</QuestionText>
        <VarticalContainer>
          <div className={'chattxt'}>준비중입니다.</div>
          <DateText>2022-01-01</DateText>
        </VarticalContainer>
      </HorizontalContainer>

      <HorizontalContainer>
        <QuestionText>Q.&nbsp;</QuestionText>
        <VarticalContainer>
          <div className={'chattxt'}>준비중입니다.</div>
          <DateText>2022-01-01</DateText>
        </VarticalContainer>
      </HorizontalContainer>
      </div>
      <button
        style={{
          backgroundColor: "#fff",
          border: "1px solid #F2F2F4",
          height:"40px",
          width: "100%",
          bottom: 0,
          cursor: "pointer",
          fontSize:14,
          textIndent:0.5
        }}
        onClick={() => {
          swal("준비중입니다.", { icon: "warning" });
        }}
      >
        채팅 관리
      </button>
    </div>
  );
}
const HorizontalContainer = styled.div`
&:last-child{

  margin-bottom: 0px;
}
  display: flex;
  margin-bottom:17px;
`;
const VarticalContainer = styled.div`
  display: block;
  margin-left: 5px;
`;
const ChatButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
`;
const ChatButtonText = styled.div`
  color: #fec009;
  margin-top:9px;
`;
const ChatButtonoff = styled.div`
  color: #000000;
  margin-top:9px;
`;
const DateText = styled.div`
  margin-top: 9px;
  margin-left:0.5px;
  color: #888888;
  font-weight:200;
`;
const QuestionText = styled.div`
  color: #fec009;
  margin-top:2px;
  vertical-align:top;
`;
