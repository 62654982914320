import React, { useEffect, useState } from "react";
import styles from "./Home_Pay.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import userSlice from "../../slice/user";
import swal from "sweetalert";

const ITEMSPERPAGE = 10;
export default function Home_Pay() {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);
  useEffect(() => {
    getPayList((page - 1) * ITEMSPERPAGE);
  }, [accesstoken]);
  const getPayList = async (offset) => {
    try {
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2500"
              : "https://dplanitp.dplanit.co.kr"
          }/admin/pay/home/list`,
          {
            headers: {
              accesstoken: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setList(res.data.data.data);
          setCount(res.data.data.data);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem('accessToken',newAct)
      dispatch(
        userSlice.actions.updateAccessToken({
          accesstoken: localStorage.getItem('accessToken'),
        })
      );
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2500"
              : "https://dplanitp.dplanit.co.kr"
          }/admin/pay/home/list`,
          {
            headers: {
              accesstoken: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setList(res.data.data.data);
          setCount(res.data.data.data);
        });
    }
  };
  return (
    <div className={`${styles.Home_container} Home_container`}>
      <div>
        <div className={`Home_title`}>결제</div>
        <img
          src={process.env.PUBLIC_URL + "/ico_reset.png"}
          className={`refresh_button`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            getPayList((page - 1) * ITEMSPERPAGE);
          }}
        />
        <div className={`Home_line`} />
        <div
          className={`${styles.Home_container_horizontal} Home_container_horizontal`}
        >
          <div>신규 결제</div>
          <div className={`${styles.item_container} item_container`}>
            <div className={`${styles.item_text} item_text`}>{count}</div>
            <div>건</div>
          </div>
        </div>
      </div>
    </div>
  );
}
