import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useAppDispatch } from "../../store";
import swal from "sweetalert";
import userSlice from "../../slice/user";

export default function Home_Service() {
  const [showList, setShowList] = useState(false);
  const [noticeList, setNoticeList] = useState([]);
  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);
  const navigate = useNavigate();
  useEffect(() => {
    getNoticeList();
  }, [accesstoken]);
  const getNoticeList = async () => {
    try {
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2500"
              : "https://dplanitp.dplanit.co.kr"
          }/admin/notice/homelist`,
          {
            headers: {
              accesstoken: accesstoken,
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setNoticeList(res.data.data);
        });
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem('accessToken',newAct)
      dispatch(
        userSlice.actions.updateAccessToken({
          accesstoken: localStorage.getItem('accessToken'),
        })
      );
      await axios
        .get(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2500"
              : "https://dplanitp.dplanit.co.kr"
          }/admin/notice/homelist`,
          {
            headers: {
              accesstoken: accesstoken,
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setNoticeList(res.data.data);
        });
    }
  };
  return (
    <div style={{ flex: 1 }}>
      <div className={`Home_title`}>서비스</div>
        
        <img
          src={process.env.PUBLIC_URL + "/ico_reset.png"}
          className={`refresh_button`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            getNoticeList();
          }}
        />
        <div className={`Home_line`}/>
      {noticeList.data &&
        noticeList.data.map((item, idx) => (
          <HorizontalContainer
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowList(!showList); // 미정
              navigate("/notice");
            }}
            key={idx}
          >
            <div>
              {item.title.length >= 10
                ? item.title.slice(0, 10) + "..."
                : item.title}
            </div>
            <ItemCntainer>
              <ArrowImg
                src={process.env.PUBLIC_URL + "/ico_arrow_right_black.png"}
                style={{ width: "16px", height: "16px" }}
              />
            </ItemCntainer>
          </HorizontalContainer>
        ))}
    </div>
  );
}

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
`;
const ItemText = styled.div`
  color: #fec009;
`;

const ItemCntainer = styled.div`
  display: flex;
  align-items: center;
`;
const ArrowImg = styled.img``;
