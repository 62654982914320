import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import styled, { css, keyframes } from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import ToggleButton from "../components/ToggleButton";
import Pagenation from "../components/Pagenation";
import ModalComponent from "../components/ModalComponent";
import PreviewModal from "../components/PreviewModal";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
import userSlice from "../slice/user";
const ITEMSPERPAGE = 10;
export default function Post() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const [modalInfo, setmodalInfo] = useState("");
  const [page, setPage] = useState(1);
  const [postList, setPostList] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState({});
  const dispatch = useAppDispatch();
  const accesstoken = useSelector((state) => state.user.accesstoken);

  const handleCheck = (e, id) => {
    const newChecked = { ...isChecked }; // 현재 isChecked 객체 복사
    newChecked[id] = e.target.checked;
    setIsChecked(newChecked);
  };

  const handleCheckAll = (e) => {
    const newChecked = {};
    postList.data.forEach((item) => {
      newChecked[item.postno] = e.target.checked;
    });
    setIsChecked(newChecked); // 복사한 isChecked 객체를 업데이트
  };

  const modalRef = useRef(null);

  useEffect(() => {
    getPostList((page - 1) * ITEMSPERPAGE);
  }, [page, loading,accesstoken]);
  const closeModal = (e) => {
    setShowModal(false);
  };
  const handleDelete = async () => {
    const checkedPostNos = [];
    Object.keys(isChecked).forEach((key) => {
      if (isChecked[key]) {
        const post = postList.data.find((post) => post.postno === Number(key));
        checkedPostNos.push(post.postno);
      }
    });

    swal({
      title: "정말 삭제하시겠습니까?",
      text: "삭제된 게시물은 복구할 수 없습니다!",
      icon: "warning",
      buttons: ["취소", "삭제"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios
            .post(
              `${
                process.env.NODE_ENV == "development"
                  ? "http://localhost:2500"
                  : "https://dplanitp.dplanit.co.kr"
              }/admin/post/delete`,
              { postno: checkedPostNos },
              {
                headers: {
                  accesstoken: localStorage.getItem("accessToken"),
                },
                withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
              }
            )
            .then((response) => {
              swal("삭제되었습니다!", {
                icon: "success",
              });
              setPostList((prevPostList) => ({
                ...prevPostList,
                data: prevPostList.data.filter(
                  (post) => !checkedPostNos.includes(post.postno)
                ),
              }));
              setIsChecked({});
            });
        } catch (error) {
          const response = await axios.get(
            `${
              process.env.NODE_ENV == "development"
                ? "http://localhost:2500"
                : "https://dplanitp.dplanit.co.kr"
            }/admin/refresh`,
            {
              headers: {
                accesstoken: localStorage.getItem("accessToken"),
              },
              withCredentials: true,
            }
          );
          
          const newAct = response.data.data.accesstoken;
            localStorage.setItem('accessToken',newAct)
            dispatch(
              userSlice.actions.updateAccessToken({
                accesstoken: localStorage.getItem('accessToken'),
              })
            );
          const response_2 = await axios
            .post(
              `${
                process.env.NODE_ENV == "development"
                  ? "http://localhost:2500"
                  : "https://dplanitp.dplanit.co.kr"
              }/admin/post/delete`,
              { postno: checkedPostNos },
              {
                headers: {
                  accesstoken: localStorage.getItem("accessToken"),
                },
                withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
              }
            )
            .then((response) => {
              swal("삭제되었습니다!", {
                icon: "success",
              });
              setPostList((prevPostList) => ({
                ...prevPostList,
                data: prevPostList.data.filter(
                  (post) => !checkedPostNos.includes(post.postno)
                ),
              }));
              setIsChecked({});
            })
            .catch((err) => {
              if (response_2.data.popdata.poptext) {
                swal(response.data.popdata.poptext, { icon: "error" });
              }
            });
        }
      } else {
        swal("취소되었습니다.");
      }
    });
  };

  const getModalInfo = async (postno) => {
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/post/preview/${postno}`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      const modifiedContent = response.data.replace(
        /src="\/upload/g,
        `src="${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/upload`
      );
      return modifiedContent;
    } catch (error) {
      const response2 = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response2.data.data.accesstoken;
      localStorage.setItem('accessToken',newAct)
      dispatch(
        userSlice.actions.updateAccessToken({
          accesstoken: localStorage.getItem('accessToken'),
        })
      );
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/post/preview/${postno}`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
        }
      );
      const modifiedContent = response.data.replace(
        /src="\/upload/g,
        `src="${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/upload`
      );
      return modifiedContent;
    }
  };
  const getPostList = async (offset) => {
    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2500"
              : "https://dplanitp.dplanit.co.kr"
          }/admin/post/list`,
          { offset },
          {
            headers: {
              accesstoken: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setPostList(res.data.data);
          setCount(res.data.data.cnt[0].cnt);
        })
    } catch (error) {
      const response = await axios.get(
        `${
          process.env.NODE_ENV == "development"
            ? "http://localhost:2500"
            : "https://dplanitp.dplanit.co.kr"
        }/admin/refresh`,
        {
          headers: {
            accesstoken: localStorage.getItem("accessToken"),
          },
          withCredentials: true,
        }
      );
      const newAct = response.data.data.accesstoken;
      localStorage.setItem('accessToken',newAct)
      dispatch(
        userSlice.actions.updateAccessToken({
          accesstoken: localStorage.getItem('accessToken'),
        })
      );
      await axios
        .post(
          `${
            process.env.NODE_ENV == "development"
              ? "http://localhost:2500"
              : "https://dplanitp.dplanit.co.kr"
          }/admin/post/list`,
          { offset },
          {
            headers: {
              accesstoken: localStorage.getItem("accessToken"),
            },
            withCredentials: true, // 브라우저가 세션 쿠키를 서버로 전송하도록 함
          }
        )
        .then((res) => {
          setPostList(res.data.data);
          setCount(res.data.data.cnt[0].cnt);
        })
    }
  };
  const openModal = async (postno) => {
    const data = await getModalInfo(postno);
    setmodalInfo(data);
    setShowModal(true);
  };
  const handlePageChange = (page) => {
    setPage(page);
  };
  return (
    <Layout
      children={
        <>
          <HomeContainer>
            <HorizontalContainer>
              <text
                style={{
                  fontSize: "30px",
                  fontFamily: "NanumBarunGothic",
                  fontWeight: 600,
                }}
              >
                포스트 관리
              </text>
              <CurrentPlaceContainer>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <text
                    style={{
                      color: "#888",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    홈 &nbsp;&nbsp;
                  </text>
                  <img
                    src={process.env.PUBLIC_URL + "/ico_arrow_right_gray.png"}
                    style={{ Width: "19px", height: "19px" }}
                  />
                  <text
                    style={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    &nbsp;&nbsp;포스트 관리
                  </text>
                </div>
              </CurrentPlaceContainer>
            </HorizontalContainer>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                padding: "38px 60px 60px 60px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <div>
                  총 <b style={{ color: "#FF7D3B" }}>{count}</b>건
                </div>
                <div>
                  <ColorButton
                    style={{
                      background: "#fff",
                      color: "#FF4D43",
                      borderColor: "#FF4D43",
                    }}
                    onClick={handleDelete}
                  >
                    <text>선택삭제</text>
                  </ColorButton>
                  <ColorButton
                    style={{
                      background: "#fff",
                      color: "#FEC009",
                      borderColor: "#FEC009",
                    }}
                    onClick={() => {
                      navigate("write");
                    }}
                  >
                    <text>등록하기</text>
                  </ColorButton>
                </div>
              </div>
              <div style={{ marginBottom: "40px" }}>
                <hr style={{ border: "1px solid #E9EAEE" }} />
              </div>
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }}>
                        <input type="checkbox" onChange={handleCheckAll} />
                      </th>
                      <th style={{ width: "26%" }}>제목</th>
                      <th style={{ width: "18%" }}>카테고리</th>
                      <th style={{ width: "18%" }}>등록일</th>
                      <th style={{ width: "18%" }}>게시여부</th>
                      <th>미리보기</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postList.data &&
                      postList.data.map((item, idx) => (
                        <tr key={item.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={isChecked[item.postno]}
                              onChange={(e) => handleCheck(e, item.postno)}
                            />
                          </td>
                          <td
                            onClick={()=>navigate("write",{
                              state:{
                                title:item.title,
                                content:item.content,
                                postno:item.postno,
                                mainimg:item.mainimg,
                                thumbimg:item.thumbimg,
                                type:item.type,
                                isEdit:true
                              }
                              }
                              )
                            }
                            style={{cursor:'pointer'}}
                            >{item.title}
                          </td>
                          <td>{item.type == 0 ? "산전임신" : "FAQ"}</td>
                          <td>{item.date}</td>
                          <td>
                            <ToggleButton
                              isActive={Boolean(item.exposure)}
                              postNo={item.postno}
                              onClick={() => {
                                setLoading(!loading);
                              }}
                              key={item.postno}
                            />
                          </td>
                          <td
                            style={{ color: "#FEC009", cursor: "pointer" }}
                            onClick={() => {
                              openModal(item.postno);
                            }}
                          >
                            확인
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <Pagenation
                  page={page}
                  itemsPerPage={10}
                  number={count}
                  handlePageChange={handlePageChange}
                />
              </TableContainer>
            </div>
          </HomeContainer>
          {ShowModal && (
            <PreviewModal
              closeModal={closeModal}
              title="포스트 미리보기"
              isOpen={ShowModal}
              content={modalInfo}
            />
          )}
        </>
      }
    ></Layout>
  );
}
const HomeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 10px;

  > * {
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
`;
const HorizontalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px 30px 60px;
`;
const CurrentPlaceContainer = styled.div`
  display: flex;
  align-items: center;
`;
const PastText = styled.div`
  color: #888888;
`;
const TableContainer = styled.div`
  font-family: "NanumBarunGothicOTF";
  font-size: 16px;
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto 50px;
  grid-gap: 10px;
  align-items: center;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    border: 1px solid #e9eaee;
    padding: 8px;
    text-align: center;
    height: 28px;
  }

  th {
    background-color: #f2f2f2;
    height: 28px;
    font-weight: 400;
  }
  grid-column: 1 / span 6;
`;

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #fff;
  width: 400px;
  padding: 20px;
  border-radius: 5px;
`;

const ModalClose = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
`;

const ModalTitle = styled.h3`
  margin-top: 0;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
  overflow-y: scroll; /* 세로 스크롤바 표시 */
  max-height: 500px; /* 모달 창의 최대 높이 */
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ColorButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #fec009;
  border-radius: 20px;
  cursor: pointer;
  height: 34px;
  width: 101px;
  border-radius: 20px;
`;
